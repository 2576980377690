.App {
  height: 100vh;
}

.Quiz {
  text-align: center;
  height: 100vh;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  /* background-image: url("../public/images/background.jpg"); */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.main-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  flex-direction: column;
  /* background-image: url("../public/images/quiz-background.jpg"); */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  gap: 2px;
}

.start-button {
  vertical-align: middle;
  margin: 5px;
  text-align: center;
  background: #db2e8c;
  border-radius: 999px;
  box-shadow: #db2e8cc4 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.answers-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.q-title {
  margin-top: 30px;
  background: #db2e8c;
  border-radius: 999px;
  box-shadow: #db2e8cc4 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.q-image {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.answers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.answer {
  vertical-align: middle;
  margin: 5px;
  text-align: center;
  background: #db2e8c;
  border-radius: 999px;
  box-shadow: #db2e8cc4 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.answer:active {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 10);
}

.link {
  text-decoration: none;
  vertical-align: middle;
  margin: 5px;
  text-align: center;
  background: #db2e8c;
  border-radius: 999px;
  box-shadow: #db2e8cc4 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 6px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

.home {
  min-height: 100vh;
  /* background-image: url("../public/images/welcome.jpg"); */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.links {
  min-height: 83vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.gamesScreen {
  /* background-image: url("../public/images/games-background.jpg"); */
  height: 100vh;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.gamesLinks {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.menuImage {
  height: 70vh;
}

.finalImg {
  width: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.resultScreen {
  padding-top: 30%;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.resultHeadline {
  padding: 10px;
  border-radius: 50px;
  box-shadow: #000000 0 2px 20px -10px;
  background: #f8f5f0;
  font-size: 1.6em;
  color: #db2e8cc4;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
}

.resultText {
  border-radius: 30px;
  color: #000000;
  padding: 10px;
  font-size: 1em;
  background-color: #f8f5f0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
}


.resultTextQuestionaire {
  border-radius: 30px;
  color: #000000;
  padding: 10px;
  font-size: 1em;
  background-color: #f8f5f0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-style: italic;
  font-weight: bold;
}

.resultScreenButtons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
}

.resultScreenButton {
  vertical-align: middle;
  margin: 5px;
  text-align: center;
  background: #db2e8c;
  border-radius: 999px;
  box-shadow: #db2e8cc4 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
  text-decoration: none;
}

.queryPage {
  background-color: #f8f5f0;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  min-height: 100vh;
  flex-direction: column;
  gap: 10px;
  font-size: 1.1em;
}

.queryValue {
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  color: #db2e8cc4;
}

.query {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.queryAnswers {
  flex-direction: row-reverse;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  gap: 2px;
}

.queryAnswer {
  vertical-align: middle;
  margin: 5px;
  text-align: center;
  background: #db2e8c;
  border-radius: 20px;
  box-shadow: #db2e8cc4 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 5px 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  /* word-break: break-word; */
  border: 0;
  text-decoration: none;
}

.queryAnswerChosen {
  background-color: gray;
}

.queryAnswerCorrect {
  background-color: green;
}

.queryAnswerIncorrect {
  background-color: red;
}

.queryAnswerCorrectNotChosen {
  /* border: 5px dashed green; */
  background-color: green;
}

.questionaireResults {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.questionaireQuestions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.loader {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
